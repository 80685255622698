/* https://codepen.io/sliiice/pen/ohHmI */

.noise {
  position: absolute;
  width: 100%;
  height: 100%;
  /* Nice old TV effect!
  background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.75) 100%
    ),
    url(./whitenoise-360x360.png) 0 0; */
  box-shadow: 0 0 500px rgba(57, 59, 78, 0.7) inset;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      ellipse at center,
      rgba(5, 10, 13, 0.25) 0%,
      rgba(5, 10, 13, 0.75) 100%
    );
    opacity: 0.5;
  }

  &::after {
    content: '';
    position: absolute;
    top: -500px;
    right: -500px;
    bottom: -500px;
    left: -500px;
    background: transparent url(./whitenoise-361x370.png) 0 0;
    background-size: 361px 370px;
    opacity: 0.4;
    animation: noize 0.3s steps(1, end) infinite both;
  }
}

@keyframes noize {
  0% {
    transform: translateX(0px, 0px);
  }
  10% {
    transform: translate(-100px, 100px);
  }
  20% {
    transform: translate(150px, -100px);
  }
  30% {
    transform: translate(-100px, 100px);
  }
  40% {
    transform: translate(100px, -150px);
  }
  50% {
    transform: translate(-100px, 200px);
  }
  60% {
    transform: translate(-200px, -100px);
  }
  70% {
    transform: translateY(50px, 100px);
  }
  80% {
    transform: translate(100px, -150px);
  }
  90% {
    transform: translate(0px, 200px);
  }
  100% {
    transform: translate(-100px, 100px);
  }
}
