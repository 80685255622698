@import '../../../variables';

.nwaStep.nwaCta {
  bottom: 1vh;
  width: 100%;
  position: absolute;
  text-align: center;
  text-shadow: 0px 0px 22px rgba(255, 255, 255, 1);
  opacity: 0;
  animation: none;
  z-index: 2;

  @include touch {
    top: calc(var(--vh, 1vh) * 2);
    left: 1rem;
    right: 1rem;
    width: auto;

    .button {
      width: 100%;
      margin-bottom: 1rem; } }

  &.is-in {
    //animation: flicker-neon 3s forwards step-end 0.5s
    animation: flicker-neon 2s forwards step-end; }

  .content p {
    font-size: 18px;
    line-height: 1.4;

    @include touch {
      font-size: 14px; }

    .oppure {
      line-height: 48px;
      padding: 0 2rem; } } }

@keyframes flicker-neon {
  0% {
    opacity: 0; }
  // 1%
  //   opacity: 0.5
  // 2.5%
  //   opacity: 0.2
  // 4.5%
  //   opacity: 0.5
  6% {
    opacity: 0; }
  23% {
    opacity: 0.5; }
  24.5% {
    opacity: 0.2; }
  26% {
    opacity: 0.5; }
  27.5% {
    opacity: 0; }
  42% {
    opacity: 0.5; }
  43.5% {
    opacity: 0; }
  60% {
    opacity: 0.6; }
  61.5% {
    opacity: 0.2; }
  63% {
    opacity: 0.6; }
  64.5% {
    opacity: 0.2; }
  66% {
    opacity: 0.6; }
  67.5% {
    opacity: 0.2; }
  69% {
    opacity: 0.6; }
  70.5% {
    opacity: 0.2; }
  72% {
    opacity: 0.6; }
  73% {
    opacity: 0; }
  85% {
    opacity: 0.7; }
  88% {
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    opacity: 1; } }
