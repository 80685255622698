@import '../../../variables';

$navbar-font-size: 12px;
$navbar-font-size-wide: 14px;

.navbar {
  border-bottom: 0.5px solid $whitish;

  .container {
    border-left: 0.5px solid $whitish;
    border-right: 0.5px solid $whitish;

    @include touch {
      border: none; } }

  .navbar-menu {
    .is-game & {
      display: none; } }

  .navbar-start {
    margin-right: 1rem;
    margin-left: auto; }

  .navbar-end {
    margin-left: 0; }

  .navbar-trailer {
    display: flex;
    align-items: stretch;
    padding-right: 0.75rem;

    @include touch {
      margin-left: auto;
      padding-right: 0; } }

  .navbar-burger {
    margin-left: 0;

    .is-game & {
      display: none; }

    &:hover, &:focus {
      background: rgba(255, 255, 255, 0.1); } }

  a.navbar-item,
  a.navbar-link,
  .tabs a {
    font-size: $navbar-font-size;
    font-family: $family-monospace;

    @include widescreen {
      font-size: $navbar-font-size-wide; }

    &:hover,
    &:focus,
    &.is-active {
      background: transparent; } }

  .navbar-menu {
    &.is-active {
      @include touch {
        border-top: 0.5px solid $whitish; } }

    a.navbar-item,
    button.navbar-item,
    a.navbar-link,
    .tabs a {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      position: relative;

      &:hover,
      &:focus,
      &.is-active,
      &[aria-current] {
        &::before {
          content: '';
          position: absolute;
          bottom: -1.5px;
          left: 0;
          right: 0;
          height: 2px;
          background: rgba($crime, 0.8);

          @include touch {
            left: 0;
            top: 0.5rem;
            bottom: 1.2rem;
            width: 2px;
            height: 1rem; } } }

      &::after {
        content: '';
        position: absolute;
        left: -1rem;
        top: 1.2rem;
        bottom: 1.2rem;
        width: 1px;
        background: $whitish;

        @include touch {
          display: none; } }

      &:first-child::after {
        display: none; } } }

  .navbar-menu .navbar-end, .navbar-brand .navbar-end {
    a.navbar-item,
    button.navbar-item,
    a.navbar-link,
    .tabs a {
      margin-right: 0;
      margin-left: 0; } }

  .navbar-menu, .navbar-middle, .navbar-brand {
    .button.navbar-item.is-text {
      line-height: 1.5;
      font-size: $navbar-font-size;
      color: $navbar-item-color;
      height: auto;
      padding: 0.5rem 1.5rem;
      font-family: $family-monospace;
      text-decoration: none;
      border: 0.5px solid $whitish;
      border-width: 0 0 0 1px;
      border-radius: 0;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      @include widescreen {
        font-size: $navbar-font-size-wide; }

      @include touch {
        padding: 0.5rem 1rem; }

      &:hover,
      &:focus,
      &.is-active {
        background: rgba(255, 255, 255, 0.1);
        color: $navbar-item-hover-color;
        border: 0.5px solid $whitish;
        border-width: 0 0 0 1px;

        @include touch {
          border-width: 0 1px 0 0; }

        &::before {
          display: none; } } } }

  a.navbar-item.logo {
    font-family: $family-condensed;
    font-weight: 800;
    font-style: italic;
    text-transform: uppercase;
    font-size: 22px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 0.75rem;
    border-right: 0.5px solid $whitish;

    @include touch {
      width: 75px;
      margin-left: 0;
      padding: 4px 14px;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0.3px; } } }
