@import '../../../variables';

.nwaTip.nwaStep {
  position: fixed;
  right: 0;
  width: 251px;
  transform: translateY(-40%);
  z-index: 2;

  @include touch {
    right: 2rem;
    transform: translate(100%, calc((var(--vh, 1vh) * 50) - #{$navbar-height * 2}));
    transition: all 400ms $easeOutQuint; }

  @include desktop {
    position: absolute;
    top: 33vh;
    right: 2rem;
    transform: translate(100%, 0%);
    transition: all 400ms $easeOutQuint; }

  @include widescreen {
    width: 281px; }

  &:after {
    content: '';
    display: block;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 26px;
    right: 0;
    height: 1px;
    background: white;
    opacity: 1;
    transform: scaleX(0.001);
    transform-origin: left center;
    transition: transform 400ms $easeOutQuint 400ms, opacity 0s linear 400ms;

    @include touch {
      opacity: 0; }

    @include desktop {
      opacity: 0; } }

  &.is-in:after {
    visibility: visible;
    transform: scaleX(1); }

  &:before {
    content: '';
    display: block;
    visibility: hidden;
    position: absolute;
    top: 26px;
    left: 0;
    bottom: 0;
    width: 1px;
    background: white;
    transform: scaleY(0.001);
    transform-origin: center bottom;
    transition: all 400ms $easeOutQuint 400ms;

    @include touch {
      opacity: 0; }

    @include desktop {
      opacity: 0; } }

  &.is-in:before {
    visibility: visible;
    transform: scaleY(1); }

  @include touch {
    transition-delay: 0;

    &.is-active {
      transform: translate(0, -40%);
      z-index: 99;

      &::after {
        opacity: 1;
        transition: transform 400ms $easeOutQuint 400ms, opacity 0s linear; }

      &::before {
        opacity: 1;
        transition: transform 400ms $easeOutQuint 400ms, opacity 0s linear; } } }

  @include desktop {
    transition-delay: 0;

    &.is-active {
      transform: translate(0, -100%);
      z-index: 99;

      &::after {
        opacity: 1;
        transition: transform 400ms $easeOutQuint 400ms, opacity 0s linear; }

      &::before {
        opacity: 1;
        transition: transform 400ms $easeOutQuint 400ms, opacity 0s linear; } } } }


.nwaTip_badge {
  position: absolute;
  top: -15px;
  left: -15px;
  height: 30px;
  width: 30px; }

.nwaTip_badge_wrapper > * {
  @include touch {
    cursor: pointer; }

  @include desktop {
    cursor: pointer; } }

@include touch {
  .nwaTip_badge_wrapper::before {
    content: 'Suggerimento';
    position: absolute;
    display: none;
    left: -23px;
    top: -15px;
    color: $aqua;
    font-size: 14px;
    font-family: $family-monospace;
    text-transform: uppercase;
    line-height: 32px;
    transform: translateX(-100%);

    .is-in & {
      display: block; }

    .is-active & {
      display: none; } } }

@include desktop {
  .nwaTip_badge_wrapper::before {
    content: 'Suggerimento';
    position: absolute;
    display: none;
    left: 14px;
    top: 15px;
    color: $aqua;
    font-size: 14px;
    font-family: $family-monospace;
    text-transform: uppercase;
    line-height: 32px;
    transform: translateX(-100%);

    .is-in & {
      display: block; }

    .is-active & {
      display: none; } } }

.nwaTip_badge path {
  transform-origin: 47.5% 47.5%;
  opacity: 0;
  transition: all 800ms $easeOutQuint 100ms; }

.is-in .nwaTip_badge path {
  opacity: 1;
  animation: pulse 400ms ease-out 100ms; }

@keyframes pulse {
  0% {
    transform: scale(0.5); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }


.nwaTip_badge circle {
  opacity: 0;
  transform-origin: 47.5% 47.5%;
  transform: rotate(-90deg);
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000; }

.is-in .nwaTip_badge circle {
  opacity: 1;
  animation: path 700ms $easeInOutQuint forwards; }

@keyframes path {
  to {
    stroke-dashoffset: 0; } }


.nwaTip_content {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  width: 251px;
  padding: 26px 52px 26px 26px;
  opacity: 0;
  transform: scale(0.8);
  transition: all 400ms $easeOutQuint 400ms;

  @include touch {
    width: auto;
    margin: 1rem;
    padding: 1rem; }

  @include desktop {
    width: auto;
    margin: 1rem;
    padding: 1rem; }

  // +widescreen
  //   width: 281px
  //   padding-right: 82px

  &::after {
    content: '';
    display: block;
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: white;
    transform: scaleX(0.001);
    transform-origin: left center;
    transition: all 400ms $easeOutQuint 400ms;

    @include touch {
      left: -1rem;
      right: -1rem;
      bottom: -1rem; }

    @include desktop {
      left: -1rem;
      right: -1rem;
      bottom: -1rem; } }

  &::before {
    @include touch {
      content: '';
      display: block;
      position: absolute;
      top: -1rem;
      bottom: -1rem;
      right: -1rem;
      width: 1px;
      background: white; }

    @include desktop {
      content: '';
      display: block;
      position: absolute;
      top: -1rem;
      bottom: -1rem;
      right: -1rem;
      width: 1px;
      background: white; } }

  .is-in &::after {
    visibility: visible;
    transform: scaleX(1); } }

.is-in .nwaTip_content {
  opacity: 1;
  transform: scale(1); }
