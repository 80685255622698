@import '../../../variables';

$dark-grey: rgb(169, 174, 192);

.nwaProgress {
  position: absolute;
  left: -1px;
  height: 25vh;
  background: rgba($dark-grey, 0.9);
  font-size: 10px;
  color: rgba($dark-grey, 0.9);
  text-transform: uppercase;
  transform: translateY(-50%);
  z-index: 101;

  @include touch {
    top: calc(var(--vh, 1vh) * -50);
    left: 0;
    right: 0;
    height: auto;
    transform: translateY(calc(#{$navbar-height}/2 + 1px)); }

  &-bar {
    width: 3px;
    background-color: $crime;
    transition: height 0.3s ease-in-out;

    &.is-mobile {
      display: none; }

    @include touch {
      display: none;

      &.is-mobile {
        display: block;
        height: 3px;
        transition: width 0.3s ease-in-out; } } }

  &-start, &-end {
    position: absolute;
    left: 14px;
    top: -8px;
    transition: color 0.1s linear 0.3s;

    @include touch {
      display: none; }

    &::before {
      display: block;
      content: '';
      position: absolute;
      left: -21px;
      top: 6px;
      height: 3px;
      width: 16px;
      background-color: rgba($dark-grey, 0.9);
      transition: background-color 0.1s linear 0.3s; } }

  &-start {
    color: $crime;

    &::before {
      background-color: $crime; } }

  &-end {
    top: auto;
    bottom: -8px; }

  &.is-end &-end {
    color: $crime; }

  &.is-end &-end::before {
    background-color: $crime; } }
