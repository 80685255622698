html {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Barlow', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code,
.code {
  font-family: 'Cousine', monospace;
}

.button + .button {
  margin-left: 1rem;
}

#root {
  height: 100%;
  background-color: #272a51;
  background-image: linear-gradient(to bottom, #347576, #272a51);
  font-family: 'Barlow', sans-serif;
}

@font-face {
  font-family: 'icomoon-nwa';
  src: url('./fonts/icomoon-nwa.ttf?vi1009') format('truetype'),
    url('./fonts/icomoon-nwa.woff?vi1009') format('woff'),
    url('./fonts/icomoon-nwa.svg?vi1009#icomoon-nwa') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-nwa' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star-o:before {
  content: '\e900';
}
.icon-star:before {
  content: '\e905';
}
.icon-youtube:before {
  content: '\e902';
}
.icon-twitter:before {
  content: '\e903';
}
.icon-facebook:before {
  content: '\e904';
}
.icon-reply:before {
  content: '\e15e';
}
.icon-trash:before {
  content: '\e901';
}
