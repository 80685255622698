@import '../../../variables';

$hold: 1500ms;

.nwaResearch {
  display: flex;
  position: absolute;
  width: 100%;
  top: -20vh;
  padding-right: 40px;
  align-items: start;
  justify-content: center;
  opacity: 1;
  transition: all 400ms $easeOutQuint;

  @include touch {
    top: calc(var(--vh, 1vh) * -20);
    padding-right: 0; } }

.nwaResearch.is-unfocused {
  transform: translateY(-15vh);
  opacity: 0.7;

  @include touch {
    transform: translateY(calc(var(--vh, 1vh) * -35)); } }

.nwaResearch.is-exiting {
  transform: translateY(-17vh);
  opacity: 0;

  @include touch {
    transform: translateY(calc(var(--vh, 1vh) * -17)); } }


// NOTIFICATION

.nwaResearch_notification {
  width: 230px;
  height: 230px;
  position: relative;
  transform: scale(1);
  transform-origin: center top;
  transition: transform 1s $easeOutQuint (2*$hold + 0.6s); }

.is-in .nwaResearch_notification {
  transform: scale(0.65); }

.nwaResearch_notification_bg {
  width: 230px;
  height: 230px;
  border-radius: 100%;
  background-color: rgba(169, 174, 192, 0.7);
  box-shadow: 0 0 90px rgba(51, 57, 67, 0.1) inset;
  opacity: 0;
  transition: all 1s $easeOutQuint 200ms; }

.is-in .nwaResearch_notification_bg {
  opacity: 1; }


// BADGE

.nwaResearch_notification_badge {
  position: absolute;
  top: 14px;
  right: -10px; }

.is-in .nwaResearch_notification_badge {
  animation: $hold + 500ms $easeOutQuint 200ms both badge; }

@keyframes badge {
  0% {
    opacity: 1;
    transform: scale(1); }
  92% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.7); } }


.nwaResearch_notification_badge use {
  transform-origin: 47.5% 47.5%;
  opacity: 0;
  transition: all 800ms $easeOutQuint 100ms; }

.is-in .nwaResearch_notification_badge use {
  opacity: 1;
  animation: pulse 400ms ease-out 100ms; }

@keyframes pulse {
  0% {
    transform: scale(0.5); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }


.nwaResearch_notification_badge circle {
  transform-origin: 47.5% 47.5%;
  transform: rotate(-90deg);
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000; }

.is-in .nwaResearch_notification_badge circle {
  animation: path 700ms $easeInOutQuint forwards; }

@keyframes path {
  to {
    stroke-dashoffset: 0; } }


// FOLDER

.nwaResearch_notification_closedFolder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 124px;
  margin-top: -50px;
  margin-left: -60px;
  opacity: 0;
  transform: scale(0.8); }

.is-in .nwaResearch_notification_closedFolder {
  animation: $hold + 500ms $easeOutQuint 200ms both closed-folder; }

@keyframes closed-folder {
  0% {
    opacity: 0;
    transform: scale(0.8); }
  10% {
    opacity: 1;
    transform: scale(0.95); }
  92% {
    opacity: 1;
    transform: scale(0.95); }
  100% {
    opacity: 0;
    transform: scale(0.7); } }


.nwaResearch_notification_folder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 105px;
  width: 136px;
  margin-top: -52.5px;
  margin-left: -68px;
  opacity: 0;
  transform: translateY(20px); }

.nwaResearch_notification_folder,
.nwaResearch_notification_folder_fold,
.nwaResearch_notification_folder_paper,
.nwaResearch_notification_folder_paper path {
  transition: all 800ms $easeOutQuint $hold + 500ms; }

.nwaResearch_notification_folder_fold {
  transform-origin: center bottom;
  transform: skewX(15deg); }

.is-in .nwaResearch_notification_folder {
  transform: translate(5px, -5px);
  animation: $hold $easeOutQuint $hold + 500ms both folder; }

@keyframes folder {
  0% {
    opacity: 0;
    transform: scale(0.8); }
  10% {
    opacity: 1;
    transform: scale(1); }
  92% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.7); } }

.is-in .nwaResearch_notification_folder_fold {
  transform: skewX(0deg); }

.is-in .nwaResearch_notification_folder_paper {
  transform-origin: center center;
  transform: translateY(-20px); }

.is-in .nwaResearch_notification_folder_paper path {
  transform-origin: center center;
  transform: translateY(20px); }

.nwaResearch_notification_document {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 172px;
  width: 132px;
  margin-top: -86px;
  margin-left: -62px;
  opacity: 0;
  transform: scale(0.75); }

.is-in .nwaResearch_notification_document {
  animation: $hold $easeOutQuint (2*$hold + 300ms) both document; }

@keyframes document {
  0% {
    opacity: 0;
    transform: scale(0.75); }
  20% {
    opacity: 1;
    transform: scale(0.9); }
  100% {
    opacity: 1;
    transform: scale(0.9); } }

// SPINNER

.nwaResearch_spinner {
  opacity: 0;
  position: absolute;
  top: 0;
  margin-top: 270px;
 }  //transition: opacity 800ms linear 800ms;

.is-in .nwaResearch_spinner {
  //opacity: 1;
  //animation: spin 1s linear infinite, badge ($hold + 400ms) $easeOutQuint 200ms both;
  animation: $hold + 200ms linear 200ms both spinner; }

@keyframes spinner {
  0% {
    opacity: 0;
    transform: rotate(0deg); }
  10% {
    opacity: 0;
    transform: rotate(72deg); }
  20% {
    opacity: 1;
    transform: rotate(144deg); }
  30% {
    transform: rotate(216deg); }
  40% {
    transform: rotate(288deg); }
  50% {
    transform: rotate(360deg); }
  60% {
    transform: rotate(432deg); }
  70% {
    transform: rotate(504deg); }
  80% {
    transform: rotate(576deg); }
  90% {
    opacity: 1;
    transform: rotate(648deg); }
  100% {
    opacity: 0;
    transform: rotate(720deg); } }


@keyframes spin {
  0% {
    transform: rotate(0deg); }
  10% {
    transform: rotate(360deg); } }
