@charset "utf-8";

@import '../node_modules/bulma/sass/utilities/initial-variables.sass';

// Colors
$primary: #272a51;
$secondary: #347576;
$aqua: #65d6c6;
$lemon: #fdf002;
$crime: #ff002b;
$whitish: #fafafa;

$info: $secondary;
$success: $aqua;
$warning: $lemon;
$danger: $crime;

$background: $primary;
$body-background-color: $primary;
$text: $whitish;
$text-strong: $text;
$title-color: $text;
$subtitle-color: $text;

$link: $whitish;
$link-visited: $whitish;
$link-active: $whitish;
$link-hover: $white;
$link-focus: $white;

// Fix dekstop breakpoint
$gap: 32px;
$desktop: 960px + (2 * $gap);

// Navbar
$navbar-height: 3.25rem;
$navbar-breakpoint: $tablet;
$navbar-item-color: $link;
$navbar-item-hover-color: $link-hover;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: $link;
$navbar-background-color: rgba(15, 22, 32, 0.9);

// Modal
$modal-content-width: 775px;
$modal-content-spacing-tablet: 12rem;
$modal-close-top: -10px;
$modal-close-right: -5px;

// Easings
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$glitchEasing: cubic-bezier(0.25, 0.46, 0.45, 0.94);

// Fonts
$family-sans-serif: "Barlow", sans-serif;
$family-monospace: "Cousine", monospace;
$family-condensed: "Barlow Condensed", Impact, sans-serif;

@import '../node_modules/bulma/sass/utilities/mixins.sass';
