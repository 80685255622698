@import '../../../variables';

$hold: 1500ms;

.nwaEmail {
  display: flex;
  position: absolute;
  width: 100%;
  top: -20vh;
  padding-right: 40px;
  align-items: start;
  justify-content: center;
  visibility: hidden;
  opacity: 1;
  transition: top 400ms $easeOutQuint, opacity 400ms $easeOutQuint;

  &.is-in {
    visibility: visible; }

  @include touch {
    top: calc(var(--vh, 1vh) * -35);
    padding-right: 0;
    flex-direction: column;
    align-items: center; } }

.nwaEmail.is-unfocused {
  top: -35vh;
  opacity: 0.7;

  @include touch {
    top: calc(var(--vh, 1vh) * -60);
    opacity: 1;

    .nwaEmail_email {
      opacity: 0.7;
      transition-delay: 0s; }

    &:hover {
      //transform: translateY(calc(var(--vh, 1vh) * -5))
      z-index: 99; }

    .nwaEmail_email:hover {
      //transform: translateY(calc(-230px + 50%))
      transform: translateY(calc(var(--vh, 1vh) * 40));
      background-color: rgba(169, 174, 192, 0.95);
      opacity: 1; } } }

.nwaEmail.is-exiting {
  top: -37vh;
  opacity: 0;

  @include touch {
    top: calc(var(--vh, 1vh) * -85); } }

// NOTIFICATION

.nwaEmail_notification {
  width: 230px;
  height: 230px;
  position: relative;
  transform: scale(1);
  transform-origin: center top;
  transition: transform 1s $easeOutQuint $hold + 0.6s, opacity 1s $easeOutQuint $hold + 0.6s; }

.is-in .nwaEmail_notification {
  transform: scale(0.65);

  @include touch {
    opacity: 0;
    transform: scale(0.65) translateY(calc(var(--vh, 1vh) * -70)); } }

.nwaEmail_notification_bg {
  width: 230px;
  height: 230px;
  border-radius: 100%;
  background-color: rgba(169, 174, 192, 0.7);
  box-shadow: 0 0 90px rgba(51, 57, 67, 0.1) inset;
  opacity: 0;
  transition: all 1s $easeOutQuint 200ms; }

.is-in .nwaEmail_notification_bg {
  opacity: 1; }

.nwaEmail_notification_badge {
  position: absolute;
  top: 14px;
  right: -10px; }

.is-in .nwaEmail_notification_badge {
  animation: $hold + 500ms $easeOutQuint 200ms both badge; }

@keyframes badge {
  0% {
    opacity: 1;
    transform: scale(1); }
  92% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.7); } }


.nwaEmail_notification_badge use {
  transform-origin: 47.5% 47.5%;
  opacity: 0;
  transition: all 800ms $easeOutQuint 100ms; }

.is-in .nwaEmail_notification_badge use {
  opacity: 1;
  animation: pulse 400ms ease-out 100ms; }

@keyframes pulse {
  0% {
    transform: scale(0.5); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }


.nwaEmail_notification_badge circle {
  transform-origin: 47.5% 47.5%;
  transform: rotate(-90deg);
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000; }

.is-in .nwaEmail_notification_badge circle {
  animation: path 700ms $easeInOutQuint forwards; }

@keyframes path {
  to {
    stroke-dashoffset: 0; } }


.nwaEmail_notification_closedLetter {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 147px;
  width: 126px;
  margin-top: -70px;
  margin-left: -63px;
  opacity: 0;
  transform: scale(0.8); }

.is-in .nwaEmail_notification_closedLetter {
  animation: $hold + 500ms $easeOutQuint 200ms both closedLetter; }

@keyframes closedLetter {
  0% {
    opacity: 0;
    transform: scale(0.8); }
  10% {
    opacity: 1;
    transform: scale(1); }
  92% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.7); } }


.nwaEmail_notification_letter {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 141px;
  width: 120px;
  margin-top: -80px;
  margin-left: -60px;
  opacity: 0;
  transform: translateY(20px); }

.nwaEmail_notification_letter,
.nwaEmail_notification_letter_edge,
.nwaEmail_notification_letter_paper,
.nwaEmail_notification_letter_paper path {
  transition: transform 800ms $easeOutQuint $hold + 500ms, opacity 800ms $easeOutQuint $hold + 500ms; }

.nwaEmail_notification_letter_edge {
  transform-origin: center 38%;
  transform: scaleY(0.3); }

.is-in .nwaEmail_notification_letter {
  opacity: 1;
  transform: translateY(0); }

.is-in .nwaEmail_notification_letter_edge {
  transform: scaleY(1); }

.is-in .nwaEmail_notification_letter_paper {
  transform-origin: center center;
  transform: translateY(-20px); }

.is-in .nwaEmail_notification_letter_paper path {
  transform-origin: center center;
  transform: translateY(20px); }

// SPINNER

.nwaEmail_spinner {
  opacity: 0;
  position: absolute;
  top: 0;
  margin-top: 270px;
 }  //transition: opacity 800ms linear 800ms;

.is-in .nwaEmail_spinner {
  //opacity: 1;
  //animation: spin 1s linear infinite, badge ($hold + 400ms) $easeOutQuint 200ms both;
  animation: $hold + 200ms linear 200ms both spinner; }

@keyframes spinner {
  0% {
    opacity: 0;
    transform: rotate(0deg); }
  10% {
    opacity: 0;
    transform: rotate(72deg); }
  20% {
    opacity: 1;
    transform: rotate(144deg); }
  30% {
    transform: rotate(216deg); }
  40% {
    transform: rotate(288deg); }
  50% {
    transform: rotate(360deg); }
  60% {
    transform: rotate(432deg); }
  70% {
    transform: rotate(504deg); }
  80% {
    transform: rotate(576deg); }
  90% {
    opacity: 1;
    transform: rotate(648deg); }
  100% {
    opacity: 0;
    transform: rotate(720deg); } }


@keyframes spin {
  0% {
    transform: rotate(0deg); }
  10% {
    transform: rotate(360deg); } }


.nwaEmail_email {
  overflow: hidden;
  width: 0;
  min-height: 268px;
  box-shadow: none;
  border: 0;
  border-radius: 6px;
  backdrop-filter: blur(29.8px);
  -webkit-backdrop-filter: blur(29.8px);
  background-color: rgba(169, 174, 192, 0.7);
  box-shadow: 0 0 400px rgba(51, 57, 67, 0.1) inset;
  color: white;
  opacity: 0;
  transform: translateY(0);
  transition: all 1s $easeOutQuint $hold + 900ms;

  @include touch {
    width: auto !important;
    min-height: auto;
    margin-left: 1rem;
    margin-right: 1rem; } }

.nwaEmail_email .card-header {
  position: relative;
  box-shadow: none;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0;
    left: 1.5rem;
    right: 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4); } }

.nwaEmail_email .card-header-title {
  color: rgba(255, 255, 255, 0.6);
  font-weight: normal;
  font-size: 12px;
  padding: 1rem 1.5rem; }

.nwaEmail_email .card-header-icon {
  color: rgba(255, 255, 255, 0.4);
  font-size: 22px;
  padding-left: 0rem;

  &:last-child {
    padding-right: 1.5rem; } }

.nwaEmail_email_from {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  padding: 0.2rem 0;

  @include touch {
    font-size: 12px; } }

.is-in .nwaEmail_email {
  opacity: 1;
  width: 690px;

  @include touch {
    width: auto;
    margin-top: -230px; } }

.nwaEmail_email_header {
  width: 640px;

  @include touch {
    width: auto; } }

.nwaEmail_email_body {
  width: 590px;
  margin-left: 49px;
  padding-left: 1rem;
  font-size: 18px;
  line-height: 1.3;

  @include touch {
    width: auto;
    margin-left: 0;
    padding-left: 0;
    font-size: 14px;
    line-height: 1.5; }

  @include widescreen {
    font-size: 20px; } }
