// https://codepen.io/meduzen/pen/zxbwRV

/* LIST OF FURTHER IMPROVEMENTS */

// 1. settings: direction of moving scanlines (currently: only bottom to top)
// 2. settings: number of moving scanlines (currently: 0 or 1)
// 3. settings: delay between 2 moving scanlines
// 4. check css validity with keyframes 0% without second step

/*
currently working on :
calculation draft for keyframes steps

$scan-moving-duration: 3s;
$scan-moving-delay: 7s;

total time = 10s (duration + delay)
keyframes :
    0%: wait
    15%: start moving
    85%: end moving & start waiting
    100% : wait

(the rest later)

*/

/* REGULAR SCANLINES SETTINGS */

// width of 1 scanline (min.: 1px)
$scan-width: 1.5px;

// emulates a damage-your-eyes bad pre-2000 CRT screen ♥ (true, false)
$scan-crt: true;

// frames-per-second (should be > 1), only applies if $scan-crt: true;
$scan-fps: 15;

// scanline-color (rgba)
$scan-color: rgba(#000, 0.4);

// set z-index on 8, like in ♥ 8-bits ♥, or…
// set z-index on 2147483648 or more to enable scanlines on Chrome fullscreen (doesn't work in Firefox or IE);
$scan-z-index: 2147483648;

/* MOVING SCANLINE SETTINGS */

// moving scanline (true, false)
$scan-moving-line: true;

// opacity of the moving scanline
$scan-opacity: 0.7;

/* MIXINS */

// apply CRT animation: @include scan-crt($scan-crt);
@mixin scan-crt($scan-crt) {
  @if $scan-crt == true {
    animation: scanlines 1s steps($scan-fps) infinite;
  } @else {
    animation: none;
  }
}

// apply CRT animation: @include scan-crt($scan-crt);
@mixin scan-moving($scan-moving-line) {
  @if $scan-moving-line == true {
    animation: scanline 6s linear infinite;
  } @else {
    animation: none;
  }
}

/* CSS .scanlines CLASS */

.scanlines {
  position: relative;
  overflow: hidden; // only to animate the unique scanline
  height: 100%;
  opacity: 0.5;

  &:before,
  &:after {
    display: block;
    pointer-events: none;
    content: '';
    position: absolute;
  }

  // unique scanline travelling on the screen
  &:before {
    // position: absolute;
    // bottom: 100%;
    width: 100%;
    height: $scan-width * 1;
    z-index: $scan-z-index + 1;
    background: $scan-color;
    opacity: $scan-opacity;
    // animation: scanline 6s linear infinite;
    @include scan-moving($scan-moving-line);
  }

  // the scanlines, so!
  &:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $scan-z-index;
    background: linear-gradient(to bottom, transparent 50%, $scan-color 51%);
    background-size: 100% $scan-width * 2;
    @include scan-crt($scan-crt);
  }
}

/* ANIMATE UNIQUE SCANLINE */
@keyframes scanline {
  0% {
    transform: translate3d(0, 200000%, 0);
    // bottom: 0%; // to have a continuous scanline move, use this line (here in 0% step) instead of transform and write, in &:before, { position: absolute; bottom: 100%; }
  }
}

@keyframes scanlines {
  0% {
    background-position: 0 50%;
    // bottom: 0%; // to have a continuous scanline move, use this line (here in 0% step) instead of transform and write, in &:before, { position: absolute; bottom: 100%; }
  }
}
