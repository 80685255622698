@import '../../variables';


.nwaScene {
  position: relative;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  overflow: visible;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    height: calc(100vh - #{2*$navbar-height});
    width: 1px;
    top: 0;
    left: 0;
    border-left: 0.5px solid $whitish;
    transform: translateY(-50%);
    z-index: 1;

    @include touch {
      border-left: none; } }

  &:after {
    left: auto;
    right: 0;
    border-left: none;
    border-right: 0.5px solid $whitish;

    @include touch {
      border-right: none; } }

  .content {
    width: auto;
    border: none; } }

.nwaStep {
  position: absolute; }

@include touch {
  .has-active-tip > *:not(.nwaTip):not(.nwaProgress):not(.HeroVideo) {
    visibility: hidden;
    opacity: 0; } }

@include desktop {
  .has-active-tip > *:not(.nwaTip):not(.nwaProgress):not(.HeroVideo) {
    opacity: 0.1; }

  .has-active-tip .nwaChoice .button {
    pointer-events: none; } }

.scene-is-exiting {
  .nwaChoice {
    animation: glitch1 0.7s 1 alternate, implode 0.5s $easeOutQuint 0.7s forwards; }

  .nwaStep:not(.nwaChoice):not(.nwaTip) > * {
    animation: tremble 0.17s 4, implode 0.5s $easeOutQuint 0.7s forwards; }

  .nwaTip > * {
    animation: tremble 0.17s 4; }

  .nwaTip {
    animation: implode 0.5s $easeOutQuint 0.7s forwards; } }

//
//.nwaStep:nth-child(4n)
//  animation: glitch1 1s 1 alternate, implode 0.5s $easeOutQuint 1s forwards
//.nwaStep:nth-child(4n+1)
//  animation: glitch2 1s 1 alternate, implode 0.5s $easeOutQuint 1s forwards
//.nwaStep:nth-child(4n+2)
//  animation: glitch3 1s 1 alternate, implode 0.5s $easeOutQuint 1s forwards
//.nwaStep:nth-child(4n+3)
//  animation: glitch4 1s 1 alternate, implode 0.5s $easeOutQuint 1s forwards


// ANIMATIONS

// $keys: 20
// $range: 180

$keys: 15;
$range: 120;

@keyframes glitch1 {
  @for $frame from 0 to $keys {
    #{ $frame * 100 / $keys + '%' } {
      $deg: random($range) - $range * 0.5 + deg;
      transform: skew($deg); } } }

@keyframes glitch2 {
  @for $frame from 0 to $keys {
    #{ $frame * 100 / $keys + '%' } {
      $deg: random($range) - $range * 0.5 + deg;
      transform: skew($deg); } } }

@keyframes glitch3 {
  @for $frame from 0 to $keys {
    #{ $frame * 100 / $keys + '%' } {
      $deg: random($range) - $range * 0.5 + deg;
      transform: skew($deg); } } }

@keyframes glitch4 {
  @for $frame from 0 to $keys {
    #{ $frame * 100 / $keys + '%' } {
      $deg: random($range) - $range * 0.5 + deg;
      transform: skew($deg); } } }

@keyframes noise {
  @for $frame from 0 to 10 {
    #{ $frame * 10%} {
      background-position: -100 + random(200) + px - 100 + random(200) + px; } } }

@keyframes bar {
  @for $frame from 0 to 10 {
    #{ $frame * 10%} {
      height: random(600) + 30px;
      top: random(100) * 1%;
      opacity: random(80) * 0.01; } } }

@keyframes implode {
  0% {
    transform: scale(1, 1);
    background-color: rgba(255, 255, 255, 0);
    border-radius: 0; }
  50% {
    transform: scale(1, 0.02);
    background-color: rgba(255, 255, 255, 0);
    border-radius: 500px; }
  100% {
    transform: scale(0.02, 0.02);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 500px; } }

/*
  .glitch
    position: absolute
    left: 0
    top: 0
    right: 0
    bottom: 0
    color: #fff
    text-align: center
    text-transform: uppercase
    font: 15px 'Michroma', helvetica, sans-serif
    letter-spacing: .6em
    background-image: url(http://api.thumbr.it/whitenoise-361x370.png?background=000000ff&noise=ffffff&density=61&opacity=20)
    text-shadow: 2px 0 1px #f6050a, -2px 0 1px #1cf2f2
    animation: noise .3s infinite

    &:before
      content: ''
      display: inline-block
      vertical-align: middle
      height: 100%
    &:after
      content: ''
      display: block
      position: absolute
      left: 0
      right: 0
      background: url(http://api.thumbr.it/whitenoise-361x370.png?background=000000ff&noise=ffffff&density=61&opacity=40)
      animation: bar .5s infinite
    span
      display: inline-block
      vertical-align: middle
      position: relative
      z-index: 1
      animation: glitch 3s infinite alternate */

@keyframes tremble {
  0% {
    transform: translateX(0%); }

  22% {
    mix-blend-mode: exclusion;
    transform: translateX(0%); }

  25.4% {
    mix-blend-mode: exclusion;
    transform: translateX(-8%); }

  46.2% {
    mix-blend-mode: difference;
    transform: scaleX(1.1) translateX(10%);
    filter: saturate(3) contrast(3); }

  47% {
    mix-blend-mode: normal;
    transform: translateX(0%);
    filter: none; }

  76% {
    transform: translateX(0%); }

  80% {
    mix-blend-mode: exclusion;
    transform: translateX(0%); }

  90% {
    mix-blend-mode: screen;
    transform-origin: right;
    transform: translateX(-6%); }

  94.2% {
    mix-blend-mode: difference;
    transform-origin: right;
    transform: translateX(-10%) scaleX(0.8);
    filter: saturate(0.3) contrast(3); }

  99.5% {
    mix-blend-mode: normal;
    transform: translateX(0%);
    filter: none; } }
