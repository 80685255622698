@import '../../../variables';

.nwaFinal {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  align-items: start;
  justify-content: center;
  transform: translateY(-50%);
  transition: transform 500ms $easeInOutQuint 2.5s, opacity 500ms $easeInOutQuint 14s;

  @include touch {
    &.is-letter {
      transition-delay: 2.5s, 12.5s; }

    &.is-phone {
      transition-delay: 7s, 13s !important; }

    &.is-unfocused {
      opacity: 0; } }

  &.is-letter, &.is-linkedin, &.is-phone {
    top: 50vh;
    transform: translateY(0);

    @include touch {
      bottom: calc(var(--vh, 1vh) * 50); } }

  &.is-phone {
    transform: translateY(20%);
    transition-delay: 7s; }

  &.is-in {
    transform: translateY(10%);

    &.is-letter, &.is-linkedin {
      transform: translateY(50%); }

    &.is-phone {
      transform: translateY(60%); } }

  &_newspaper {
    width: 90%;
    max-width: 785px;
    opacity: 0;
    transform: scale(0.2)  rotate(-180deg);

    .is-in & {
      animation: 1s spin ease-in forwards; }

    img {
      width: 100%;
      height: auto; } }

  &_letter, &_linkedin, &_phone {
    width: 90%;
    max-width: 785px;
    opacity: 0;
    transform: scale(0.8) translateY(-100%);
    transform-origin: 50% 60%;
    transition: all 1.5s $easeOutQuint;

    .is-in & {
      opacity: 1;
      transform: scale(1) translateY(-100%); }

    img {
      width: 100%;
      height: auto; } }

  @include touch {
    &_phone {
      width: 100%;

      .is-in & {
        transform: scale(0.5) translateY(-170%); }

      .is-unfocused & {
        transform: scale(0.5) translateY(-140%); } } }


  &_iphone {
    position: relative;
    width: 374px;
    margin: 0 auto;

    @include touch {
      width: 300px; }

    .nwaFinal_messages {
      position: absolute;
      width: 100%;
      top: 0;
      transform: translateY(0);

      .is-in & {
        animation: 3s messages $easeOutQuint forwards 2s; } }

    .nwaFinal_message {
      position: absolute;
      width: 320px;
      left: 26px;
      opacity: 0;
      transition: opacity 200ms ease-out;

      @include touch {
        width: 257px;
        left: 21px; }

      .is-in & {
        opacity: 1; }

      &.is-message-1 {
        top: 265px;
        transition-delay: 2s;

        @include touch {
          top: calc(265px * 0.802); } }

      &.is-message-2 {
        top: calc(265px + 90px);
        transition-delay: 3s;

        @include touch {
          top: calc((265px + 90px) * 0.802); } }

      &.is-message-3 {
        top: calc(265px + 90px + 105px);
        transition-delay: 3.9s;

        @include touch {
          top: calc((265px + 90px + 105px) * 0.802); } }

      &.is-message-4 {
        top: calc(265px + 90px + 105px + 90px);
        transition-delay: 4.4s;

        @include touch {
          top: calc((265px + 90px + 105px + 90px) * 0.802); } }

      &.is-message-5 {
        top: calc(265px + 90px + 105px + 90px + 90px);
        transition-delay: 4.9s;

        @include touch {
          top: calc((265px + 90px + 105px + 90px + 90px) * 0.802); } } } } }

@keyframes spin {
  0% {
    opacity: 0;
    transform: scale(0.2) rotate(-180deg); }

  100% {
    opacity: 1;
    transform: scale(1) rotate(1080deg); } }

@keyframes messages {
  0% {
    transform: translateY(0); }

  76% {
    transform: translateY(0); }

  84% {
    transform: translateY(-70px); }

  92% {
    transform: translateY(-70px); }

  100% {
    transform: translateY(-140px); } }

@include touch {
  @keyframes messages {
    0% {
      transform: translateY(0); }

    76% {
      transform: translateY(0); }

    84% {
      transform: translateY(calc(-70px * 0.802)); }

    92% {
      transform: translateY(calc(-70px * 0.802)); }

    100% {
      transform: translateY(calc(-140px * 0.802)); } } }
