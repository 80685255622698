@import '../../../variables';

.nwaNarrator {
  top: 12vh;
  width: 100%;
  position: absolute;
  text-align: center;

  @include touch {
    min-height: 63px;
    display: flex;
    align-items: center; }

  &.is-exiting {
    display: none; }

  &.is-final {
    top: -38vh; }

  @include widescreen {
    top: 10vh; }

  @include touch {
    top: calc(var(--vh, 1vh) * -10);

    &.is-final {
      top: calc(var(--vh, 1vh) * -38); } }

  .Typist {
    max-width: 520px;
    margin: 0 auto;
    font-family: $family-monospace;
    font-size: 21px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.19);
    line-height: 1.3;

    @include touch {
      margin: 0 1rem;
      text-align: left;
      font-size: 14px;
      line-height: 1.5; }

    @include widescreen {
      max-width: 800px;
      font-size: 25px; } }

  &.is-final .Typist {
    max-width: 90%;
    font-size: 18px;
    line-height: 1.6;

    @include touch {
      font-size: 14px;
      line-height: 1.5; } } }


/*
 *@media screen and (max-width: 1199px) {
 *  .nwaNarrator .column {
 *    display: none;
 *  }
 *
 *  .nwaNarrator .column.is-two-thirds {
 *    display: block;
 *  }
 *} */
