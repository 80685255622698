@import '../../../variables';

.nwaChat {
  position: absolute;
  width: 80%;
  max-width: 505px;
  bottom: -10vh;
  left: 50%;
  top: calc(-50vh + #{$navbar-height});
  transform: translateX(-50%);
  transition: all 400ms $easeOutQuint;

  @include touch {
    left: 1rem;
    right: 1rem;
    bottom: calc(var(--vh, 1vh) * -10);
    top: calc(calc(var(--vh, 1vh) * -50) + #{$navbar-height}/2);
    width: auto;
    transform: none;
    z-index: 2; } }

.nwaChat.is-unfocused {
  bottom: 5vh;
  opacity: 0.7;

  @include touch {
    bottom: calc(var(--vh, 1vh) * 13); } }

.nwaChat_container {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  overflow: hidden; }

.nwaChat_scroller {
  position: absolute;
  bottom: 0;
  left: 0;
  right: -3rem;
  padding: 0 7rem 0 4rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  @include touch {
    padding: 0 5.5rem 0 2.5rem; } }

.nwaChat .message {
  border-radius: 8px;
  margin-bottom: 0;
  margin-top: 1.5rem;
  position: relative; }

.nwaChat .message-body {
  font-size: 20px;
  border-radius: 6px;
  border-width: 0;

  @include touch {
    font-size: 16px;
    line-height: 1.25; } }

.nwaChat .message.is-primary {
  background-color: rgba(169, 174, 192, 0.4);
  border-top-right-radius: 0; }

.nwaChat .message.is-dark {
  background-color: rgba(169, 174, 192, 0.6);
  border-top-left-radius: 0; }

.nwaChat .message.is-primary .message-body {
  text-align: right;
  color: rgb(227, 219, 219);
  border-width: 0 6px 0 0;
  border-color: $aqua;
  border-top-right-radius: 0; }

.nwaChat .message.is-dark .message-body {
  color: white;
  border-top-left-radius: 0; }

.nwaChat .typing > div {
  width: 8px;
  height: 8px;
  background-color: rgba(227, 219, 219, 0.8);
  margin: 0 1.5px;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both; }

.nwaChat .typing .bounce1 {
  animation-delay: -0.32s; }

.nwaChat .typing .bounce2 {
  animation-delay: -0.16s; }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.nwaChat .message-avatar {
  position: absolute;
  height: 43px;
  width: 43px;
  top: 0;
  left: -60px;
  border-radius: 99px;
  border: 1px solid $whitish;
  background-color: rgba(169, 174, 192, 0.6);

  @include touch {
    height: 25px;
    width: 25px;
    left: -40px; } }

.nwaChat .is-player .message-avatar {
  left: auto;
  right: -60px;
  border-color: $aqua;

  @include touch {
    right: -40px; } }

.nwaScene .nwaChat_input.content {
  position: absolute;
  width: 100%;
  top: calc(100% + 11vh);
  opacity: 0;
  transition: opacity 300ms $easeOutQuint;

  &.is-visible {
    opacity: 1; }

  .button.is-game {
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 99px;
    animation: none;

    &::before, &::after {
      border-radius: 99px;
      animation: none; } }

  .input {
    height: 50px;
    padding-top: 0.2rem;
    padding-left: 1rem;
    padding-right: 3rem;
    background: transparent url(../../../images/icons/smile.svg) right 1rem top 13px no-repeat;
    background-size: 20px 20px;
    font-size: 20px;
    font-family: $family-sans-serif;
    border: 2px solid $crime;
    border-radius: 99px;

    &:focus {
      background-color: rgba($whitish, 0.1); }

    &::after {} }

  ::placeholder {
    color: $whitish;
    opacity: 0.7; }

  ::-webkit-input-placeholder {
    color: $whitish;
    opacity: 0.7; } }

