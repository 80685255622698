@import '../../../variables';

$hold: 1500ms;

// NOTIFICATION FWD

.nwaEmail_notification_fwd {
  position: absolute;
  top: 14px;
  right: -10px; }

.is-out .nwaEmail_notification {
  top: -9999px; }

.is-in .nwaEmail_notification_fwd {
  animation: $hold + 500ms $easeOutQuint 200ms both badge-fwd; }

@keyframes badge-fwd {
  0% {
    opacity: 1;
    transform: scale(1); }
  92% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 1;
    transform: scale(0.7); } }


.nwaEmail_notification_fwd {
  &_fwd {
    transform-origin: 47.5% 47.5%;
    opacity: 0;
    transition: all 800ms $easeOutQuint 100ms; }

  &_ok {
    transform-origin: 47.5% 47.5%;
    opacity: 0;
    transition: all 800ms $easeOutQuint ($hold + 500ms); } }

.is-in .nwaEmail_notification_fwd {
  &_fwd {
    animation: pulse-disappear ($hold + 500ms) ease-out 100ms; }

  &_ok {
    opacity: 1;
    animation: pulse-ok 400ms ease-out ($hold + 500ms); } }

@keyframes pulse-ok {
  0% {
    transform: scale(0.5); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

@keyframes pulse-disappear {
  0% {
    transform: scale(0.5);
    opacity: 0; }
  15% {
    transform: scale(1.1); }
  30% {
    transform: scale(1); }
  60% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }


.nwaEmail_notification_fwd circle {
  transform-origin: 47.5% 47.5%;
  transform: rotate(-90deg);
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000; }

.is-in .nwaEmail_notification_fwd circle {
  animation: path-fwd 700ms $easeInOutQuint forwards; }

@keyframes path-fwd {
  to {
    stroke-dashoffset: 0; } }
