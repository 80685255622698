@import '../../../variables';

.nwaChoice {
  top: 29vh;
  width: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;

  @include widescreen {
    top: 30vh; }

  @include touch {
    top: calc((var(--vh, 1vh) * 47.5) - #{$navbar-height * 2});
    padding: 0 1rem;
    transform: translateY(-100%);

    .button {
      width: 100%;
      margin-bottom: 1rem;

      & + .button {
        margin-left: 0; } } }

  &.is-in {
    opacity: 1; } }
