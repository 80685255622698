@import '../../../variables';

.hero-foot .navbar {
  border-top: 0.5px solid $whitish;
  border-bottom: none;

  .navbar-brand {
    margin: 0;
    width: 100%; }

  .navbar-start {
    display: flex;
    align-items: stretch;
    margin-right: auto;
    margin-left: 1.5rem;

    @include touch {
      margin-left: 0;
      width: 105px;
      border-right: 0.5px solid $whitish; } }

  .navbar-end {
    display: flex;
    align-items: stretch;

    .is-game & {
      display: none; }

    @include touch {
      margin-left: auto; } }

  .navbar-brand .navbar-start {
    a.navbar-item,
    button.navbar-item,
    a.navbar-link,
    .tabs a {
      margin-left: 0.3rem;
      margin-right: 0.3rem;

      &::after {
        content: '';
        position: absolute;
        left: -0.3rem;
        top: 1.2rem;
        bottom: 1.2rem;
        width: 1px;
        background: $whitish;

        @include touch {
          display: none; } }

      &:first-child::after {
        display: none; } }

    button.navbar-item.is-text {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      @include touch {
        padding-left: 1rem;
        padding-right: 1rem; }

      &:hover,
      &:focus,
      &.is-active {
        border: none; } } }

  .navbar-brand button.navbar-item.is-text {
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    @include touch {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      border-right: none; } }

  .navbar-brand .navbar-start button.navbar-item.is-text {
    border: none;
    background: transparent; } }
