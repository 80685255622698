@import '../../../variables';
@import './scanlines';
@import './noise';

.HeroVideo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: black;

  @include touch {
    height: calc(var(--vh, 1vh) * 100); } }

.HeroVideo_container,
.HeroVideo_video,
.HeroVideo_poster,
.HeroVideo_filter {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity 500ms linear; }

.HeroVideo_container {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.HeroVideo_poster {
  //filter: blur(10px);
  object-fit: cover;
  object-position: center center; }

.HeroVideo .is-out {
  opacity: 0; }

.HeroVideo_filter {
  background: rgba(27, 33, 42, 0.6);
  box-shadow: 0 0 500px rgba(57, 59, 78, 0.7) inset; }
